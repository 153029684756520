export interface NotificationI {
  title: string;
  description: string;
  status: string;
  redirectUrl: string;
}
export const NotificationDataInit = {
  title: "",
  description: "",
  status: "",
  redirectUrl: "",
};
