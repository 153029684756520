export const waterFootprintDataInit = {
  _id: "",
  collection_id: "",
  crop_id: "",
  blue_component: 0,
  green_component: 0,
  grey_component: 0,
  total: 0,
  ia_suggestion: "",
  createdAt: "",
  updatedAt: "",
};

export interface IASuggestionI {
  content: string;
}
export const IASuggestionDataInit: IASuggestionI = {
  content: "",
};
